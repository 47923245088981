import _ from 'lodash'
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import typography from './typography'
import { Columns } from 'react-feather'

const baseOptions = {
  typography,
  overrides: {
    MuiTypography: {
      displayBlock: {
        color: '#4e5156',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#292727',
        border: '1px solid #ececec',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        '&:hover': {
          backgroundColor: '#292727',
        },
        '&$focused': {
          backgroundColor: '#292727',
        },
      },
      underline: {
        '&:before': {
          // borderBottomColor: "red"
        },
        '&:hover:not(.Mui-focused):before': {
          // borderBottomColor: "green"
        },
        '&:after': {
          // focused
          // borderBottomColor: "purple"
        },
      },
    },

    MuiInputBase: {
      root: {
        padding: '1px 8px',
      },
    },
    MuiInputLabel: {
      filled: {
        color: '#FFF',
        '&$focused': {
          color: '#0261ed',
        },
        '.MuiFormControl-root:hover &:not($focused)': {
          color: '#0261ed',
        },
      },
      outlined: {
        fontSize: '14px',
        zIndex: '1',
        transform: 'translate(14px, 10px) scale(1)',
      },
    },
    MuiOutlinedInput: {
      root: {
        height: '40px',
        fontSize: '14px',
      },
      input: {
        padding: '5px',
      },
      multiline: {
        height: 'auto',
      },
    },
    MuiDropzoneArea: {
      root: {
        height: '175px',
        minHeight: '250px',
        marginBottom: '15px',
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
        fontWeight: 400,
        fontSize: '16px',
        padding: '2px 25px',
        margin: '10px 0 15px',
      },
      barColorPrimary: {
        background: 'rgba(34,196,254)',
        background:
          'linear-gradient(29deg, rgba(34,196,254,1) 0%, rgba(0,89,236,1) 100%, rgba(0,212,255,1) 100%)',
      },
      colorPrimary: {
        backgroundColor: '#efefef',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
        color: '#222',
      },
    },
    MuiListItemText: {
      root: {
        color: '#222',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#000',
      },
      containedPrimary: {
        background: 'rgba(34,196,254)',
        background:
          'linear-gradient(29deg, #f44336 0%, rgb(226 81 66) 100%, #f44336 100%)',
        boxShadow: 'none',
        borderRadius: 5,
        padding: '1px 14px',
        height: '35px',
        color: '#fff',
        '&:hover': {
          boxShadow: 'none',
          color: '#fff',
          backgroundColor: 'rgba(0,212,255,1)',
        },
      },
      containedSecondary: {
        background: 'rgba(34,196,254)',
        background:
          'linear-gradient(29deg, rgba(34,196,254,1) 0%, rgba(0,89,236,1) 100%, rgba(0,212,255,1) 100%)',
        boxShadow: 'none',
        borderRadius: 5,
        padding: '1px 14px',
        height: '35px',
        color: '#fff',
        minWidth: '102px',
        '&:hover': {
          boxShadow: 'none',
          color: '#fff',
          backgroundColor: 'rgba(0,212,255,1)',
        },
      },
      containedSizeLarge: {
        color: '#fff',
        background: 'rgba(34,196,254)',
        background:
          'linear-gradient(29deg, rgba(34,196,254,1) 0%, rgba(0,89,236,1) 100%, rgba(0,212,255,1) 100%)',
      },
      outlined: {
        boxShadow: 'none',
        borderRadius: 5,
        borderWidth: 2,
      },
      outlinedPrimary: {
        borderRadius: 5,
        color: colors.common.white,
        borderColor: colors.common.white,
        boxShadow: 'none',

        '&:hover': {
          color: colors.primary,
        },
      },
      outlinedSizeLarge: {},
    },
    MuiTableCell: {
      root: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '150px',
      },
      stickyHeader: {
        backgroundColor: '#0b7af2',
        fontWeight: '600',
        color: '#fff',
      },
    },
    MuiPaper: {
      root: {},
      outlined: {
        backgroundColor: '#fbfdff',
        padding: '10px',
        borderRadius: '0',
        border: '0',
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: 'row',
      },
    },
    MuiLink: {
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: '0',
      },
    },
  },
}

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: 'light',
    action: {
      primary: '#20509e',
    },
    background: {
      default: '#FBFBFD',
      dark: '#f3f7f9',
      paper: colors.common.white,
    },
    primary: {
      main: '#0261ed',
      dark: '#0261ed',
      light: '#0261ed',
    },
    secondary: {
      main: '#3b0668',
    },
    warning: {
      main: '#ffae33',
      dark: '#ffae33',
      light: '#fff1dc',
    },
    success: {
      main: '#54e18c',
      dark: '#54e18c',
      light: '#e2faec',
    },
    error: {
      main: '#ff7d68',
      dark: '#ff7d68',
      light: '#ffe9e6',
    },
    text: {
      primary: '#52565c',
      secondary: '#999999',
    },
    common: {
      black: '#222222',
    },
  },
}

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions))

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
