import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Link,
  makeStyles,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import FacebookIcon from "@material-ui/icons/Facebook";
import { FaTelegramPlane } from "react-icons/fa";
import {
  RiInstagramLine,
  RiTwitterFill,
  RiYoutubeFill,
  RiMailLine,
  RiSmartphoneLine,
} from "react-icons/ri";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { MdLocationOn } from "react-icons/md";
import ListItemText from "@material-ui/core/ListItemText";
import DraftsIcon from "@material-ui/icons/Drafts";
import {} from "react-feather";
import axios from "axios";
import apiConfig from "src/asset/apiConfig";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#0d1015",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
    },
  },
  LowerFooter: {
    background: "#000",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  listItm: {
    marginLeft: "-15px",
    marginTop: "-27px",
    "@media (max-width: 1000px)": {
      marginLeft: "0px",
      marginTop: "-17px",
    },
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
}));

export default function Liquidity() {
  const classes = useStyles();

  const [sideDataList, setSideDataList] = useState();
  const [contactUsData, setContactUsData] = useState();
  const sideDataListHandler = async (type) => {
    try {
      const res = await axios.get(apiConfig.sideDataList, {
        params: {
          type,
        },
      });
      if (res.data.response_code === 200) {
        setSideDataList(res.data.result);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const contactUsHandler = async (type) => {
    try {
      const res = await axios.get(apiConfig.contactUs);
      if (res.data.response_code === 200) {
        setContactUsData(res.data.result[0]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    contactUsHandler();
    sideDataListHandler();
  }, []);

  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Box className="widgettitle">
                <img src="/images/logo.png" alt="R2V4" />
                <Box mt={3}>
                  <Typography style={{color:"#52565c"}} variant="body2" className="">
                    {sideDataList && sideDataList.description.data}
                  </Typography>
                </Box>
                <Box className="socialLink footerlink">
                  {sideDataList &&
                    sideDataList.Instagram &&
                    sideDataList.Instagram.url && (
                      <Link href={sideDataList.Instagram.url}>
                        <RiInstagramLine style={{ color: "#fff " }} />
                      </Link>
                    )}
                  {sideDataList &&
                    sideDataList.Twitter &&
                    sideDataList.Twitter.url && (
                      <Link href={sideDataList.Twitter.url}>
                        <RiTwitterFill style={{ color: "#fff " }} />
                      </Link>
                    )}
                  {sideDataList &&
                    sideDataList.Youtube &&
                    sideDataList.Youtube.url && (
                      <Link href={sideDataList.Youtube.url}>
                        <RiYoutubeFill style={{ color: "#fff  " }} />
                      </Link>
                    )}
                  {/* {
                    sideDataList &&
                    sideDataList.description.Youtube &&
                    sideDataList.description.Youtube.url &&

                  <Link href={sideDataList.description.Youtube.url}>
                    <FaTelegramPlane style={{ color: '#fff  ' }} />
                  </Link>
} */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box className="widgettitle">
                <Typography variant="h3">Terms of use</Typography>
                <List className="linksFooter">
                  <ListItem>
                    <RouterLink
                      style={{ textDecoration: "none" }}
                      to="/legal-notice"
                    >
                      Legal Notice
                    </RouterLink>
                  </ListItem>
                  <ListItem>
                    <RouterLink
                      style={{ textDecoration: "none" }}
                      to="/disclaimer"
                    >
                      Disclaimer
                    </RouterLink>
                  </ListItem>
                  <ListItem>
                    <RouterLink
                      style={{ textDecoration: "none" }}
                      to="/terms-conditions"
                    >
                      Terms & Conditions
                    </RouterLink>
                  </ListItem>
                  <ListItem>
                    <RouterLink
                      style={{ textDecoration: "none" }}
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </RouterLink>
                  </ListItem>
                  <ListItem>
                    <RouterLink
                      style={{ textDecoration: "none" }}
                      to="/cookies-policy"
                    >
                      Cookies Policy
                    </RouterLink>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box className="widgettitle">
                <Typography variant="h3">Services</Typography>
                <List className="linksFooter">
                  <ListItem>
                    <RouterLink style={{ textDecoration: "none" }} to="/">
                      Home
                    </RouterLink>
                  </ListItem>
                  {/* <ListItem>
                    <RouterLink>Connect</RouterLink>
                  </ListItem> */}
                  <ListItem>
                    <RouterLink style={{ textDecoration: "none" }} to="/nft">
                      Connect
                    </RouterLink>
                  </ListItem>
                  <ListItem>
                    <RouterLink style={{ textDecoration: "none" }} to="/nft">
                      Browse
                    </RouterLink>
                  </ListItem>
                  <ListItem>
                    <RouterLink style={{ textDecoration: "none" }} to="/nft">
                      Support
                    </RouterLink>
                  </ListItem>
                  {/* <ListItem>
                    <RouterLink>Support</RouterLink>
                  </ListItem> */}
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box className="widgettitle reachLocation">
                <Typography variant="h3">Reach us</Typography>
                <Box className="recent-posts-widget">
                  <List
                    className={classes.listItm}
                    component="nav"
                    aria-label="main mailbox folders"
                  >
                    <ListItem button > 
                      <ListItemIcon>
                        <RiMailLine style={{ color: "#1fbbfd  " }} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={contactUsData && contactUsData.email}
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <RiSmartphoneLine style={{ color: "#1fbbfd  " }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={contactUsData && contactUsData.mobileNumber}
                      />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon>
                        <MdLocationOn style={{ color: "#1fbbfd  " }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={contactUsData && `${contactUsData.address},`}
                      />
                    </ListItem>
                  </List>{" "}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.LowerFooter}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box className="FooterMenu copyright ">
                <Typography variant="body2" className="">
                  © {moment().format("YYYY")} R2V4. All Rights Reserved
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="FooterMenu">
                <List>
                  <ListItem>
                    <RouterLink
                      style={{ textDecoration: "none" }}
                      href="/"
                      color="inherit"
                    >
                      <Typography variant="body2" className="">
                        Home
                      </Typography>
                    </RouterLink>
                  </ListItem>
                  {/* <ListItem>
                    <Link href="/" color="inherit">
                      <Typography variant="body2" className="">
                        Documentation
                      </Typography>
                    </Link>
                  </ListItem> */}
                  <ListItem>
                    <Link href={false} color="inherit">
                      <Typography variant="body2" className="">
                        Documentation
                      </Typography>
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href={false} color="inherit">
                      <Typography variant="body2" className="">
                        Discord
                      </Typography>
                    </Link>
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
