import React from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  Link,
  makeStyles,
} from '@material-ui/core';
import { MdOpenInBrowser } from 'react-icons/md';
import { RiGift2Line, RiCoupon5Line, RiCopperCoinLine } from 'react-icons/ri';
import { HiOutlineCollection } from 'react-icons/hi';
import { IoMdGift } from 'react-icons/io';
import {} from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  headerSection: {
    background: 'rgba(34,196,254)',
    background:
      'linear-gradient(29deg, rgba(34,196,254,1) 0%, rgba(0,89,236,1) 100%, rgba(0,212,255,1) 100%)',
    top: '68px',
    position: 'absolute',
    width: '100%',
    left: '0',
    zIndex: '9',
    maxWidth: '100%',
    overflow: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },

  iconText: {
    width: 18,
    height: 18,
    marginRight: '8px',
    color: '#fff',
  },
  iconBox: {
    display: 'flex',
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.headerSection}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Box className="secondaryHeader">
              {/* <Link to="/my-nft-list" component={RouterLink}>
                <Box className={classes.iconBox}>
                  <RiCopperCoinLine className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    My NFTs
                  </Typography>
                </Box>
              </Link> */}
              <Link to="/nft" component={RouterLink}>
                <Box className={classes.iconBox}>
                  <MdOpenInBrowser className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Browse
                  </Typography>
                </Box>
              </Link>
              <Link to="/gift-card" component={RouterLink}>
                <Box className={classes.iconBox}>
                  <RiGift2Line className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Gifts Card
                  </Typography>
                </Box>
              </Link>
              <Link to="/gift-voucher" component={RouterLink}>
                <Box className={classes.iconBox}>
                  <IoMdGift className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Gift Voucher
                  </Typography>
                </Box>
              </Link>
              <Link to="/discount-coupon" component={RouterLink}>
                <Box className={classes.iconBox}>
                  <RiCoupon5Line className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Discount Coupon
                  </Typography>
                </Box>
              </Link>
              <Link to="/non-profit" component={RouterLink}>
                <Box className={classes.iconBox}>
                  <RiCopperCoinLine className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Non-Profit
                  </Typography>
                </Box>
              </Link>
              <Link to="/collectibles" component={RouterLink}>
                {' '}
                <Box className={classes.iconBox}>
                  <HiOutlineCollection className={classes.iconText} />
                  <Typography variant="body2" className="iconText">
                    Collectibles
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
