import React from 'react'
import { makeStyles } from '@material-ui/core'
import Footer from './Footer'
import Header from './Header'
import TopBar from './TopBar'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

const MainLayout = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TopBar />
      <Header />
      <div style={{ background: '#fff', minHeight: '650px' }}>{children}</div>
      <Footer />
    </div>
  )
}

export default MainLayout
