// let url;
// let baseurl = 'http://172.16.2.11:1859'
let baseurl = 'https://node-smartcontract.mobiloitte.org'

const apiConfig = {
  //user
  createCollection: `${baseurl}/api/v1/user/createCollection`,
  connectWallet: `${baseurl}/api/v1/user/connectWallet`,
  collectionlist: `${baseurl}/api/v1/user/collectionlist`,
  viewCollection: `${baseurl}/api/v1/user/viewCollection`,
  editProfile: `${baseurl}/api/v1/user/editProfile`,
  getProfile: `${baseurl}/api/v1/user/getProfile`,
  contactUs: `${baseurl}/api/v1/user/contactUs`,
  collectionSearch: `${baseurl}/api/v1/user/collectionSearch`,
  userDashboard: `${baseurl}/api/v1/user/userProfileDashboard`,
  viewCollectionWithNFT: `${baseurl}/api/v1/user/viewCollectionWithNFT`,

  //NFT
  addImage: `${baseurl}/api/v1/nft/addImage`,
  uploadNFT: `${baseurl}/api/v1/nft/uploadNFT`,
  addNFT: `${baseurl}/api/v1/nft/addNft`,
  placeOrder: `${baseurl}/api/v1/nft/placeOrder`,
  listNft: `${baseurl}/api/v1/nft/listNft`,
  placeBid: `${baseurl}/api/v1/nft/placeBid`,
  sellOrder: `${baseurl}/api/v1/nft/sellOrder`,
  PlaceOrderList: `${baseurl}/api/v1/nft/placeOrderList`,
  placeOrderListById: `${baseurl}/api/v1/nft/placeOrderListById`,
  listNftParticular: `${baseurl}/api/v1/nft/listNftParticular`,
  orderListParticular: `${baseurl}/api/v1/nft/orderListParticular`,
  bidList: `${baseurl}/api/v1/nft/bidList`,
  acceptBid: `${baseurl}/api/v1/nft/acceptBid`,
  rejectBid: `${baseurl}/api/v1/nft/rejectBid`,
  withoutPlaceNftList: `${baseurl}/api/v1/nft/withoutPlaceNftList`,
  transfer: `${baseurl}/api/v1/nft/transferNFT`,
  //sideData
  sideDataList: `${baseurl}/api/v1/sideData/sideDataList`,
  //static
  staticList: `${baseurl}/api/v1/static/staticList`,
}

export default apiConfig
