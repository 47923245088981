import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import HomeLayout from 'src/layouts/HomeLayout'

export const routes = [
  {
    exact: true,
    path: '/',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Home')),
  },

  {
    exact: true,
    path: '/my-collection',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Collection/Collection')),
  },
  {
    exact: true,
    path: '/manage-collection',
    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/Collection/ManageCollection'),
    ),
  },
  {
    exact: true,
    path: '/my-nft',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/MyNFT/MyNFT')),
  },
  {
    exact: true,
    path: '/my-nft-list',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/NFTList/index')),
  },
  {
    exact: true,
    path: '/manage-nft',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/MyNFT/ManageNFT')),
  },
  {
    exact: true,
    path: '/nft-offer',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/MyNFT/NftOffer')),
  },
  {
    exact: true,
    path: '/my-auction',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Auction/MyAuction')),
  },
  {
    exact: true,
    path: '/my-redeem',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Profile/MyRedeem')),
  },
  {
    exact: true,
    path: '/account-review',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Profile/AccountReview')),
  },
  {
    exact: true,
    path: '/gift-card',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/GiftCard/GiftCard')),
  },
  {
    exact: true,
    path: '/gift-voucher',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/GiftVoucher/GiftVoucher')),
  },
  {
    exact: true,
    path: '/discount-coupon',
    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/DiscountCoupons/DiscountCoupons'),
    ),
  },
  {
    exact: true,
    path: '/non-profit',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/NonProfit/NonProfit')),
  },
  {
    exact: true,
    path: '/collectibles',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Collectibles/Collect')),
  },
  {
    exact: true,
    path: '/favourites',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Favourites/Favourites')),
  },
  {
    exact: true,
    path: '/nft',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/NFT/NFT')),
  },
  // {
  //   exact: true,
  //   path: '/my-collection',
  //   layout: HomeLayout,
  //   component: lazy(() => import('src/views/pages/UsersView/UserCollectios')),
  // },
  {
    exact: true,
    path: '/user',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/UsersView/UsersView')),
  },
  {
    exact: true,
    path: '/nft-collection',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/NFTCollectionCard/Stepper2')),
  },
  {
    exact: true,
    path: '/collection',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Collection/CollectionView')),
  },
  {
    exact: true,
    path: '/create-collection',
    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/Collection/CreateCollection'),
    ),
  },
  {
    exact: true,
    path: '/select-create-collection',
    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/Collection/SelectCollectionType'),
    ),
  },
  {
    exact: true,
    path: '/resume-creation',
    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/Create-NFT/SelectCollection'),
    ),
  },
  {
    exact: true,
    path: '/select-collection',
    layout: HomeLayout,
    component: lazy(() =>
      import('src/views/pages/Create-NFT/SelectCollection'),
    ),
  },
  {
    exact: true,
    path: '/about-us',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/About/AboutUs')),
  },
  {
    exact: true,
    path: '/wallet',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Wallet/Wallet')),
  },
  {
    exact: true,
    path: '/search',
    layout: HomeLayout,
    component: lazy(() => import('src/Search/Search')),
  },

  {
    exact: true,
    path: '/terms-conditions',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/TermsConditions')),
  },
  {
    exact: true,
    path: '/legal-notice',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/LegalNotice')),
  },
  {
    exact: true,
    path: '/cookies-policy',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/CookiesPolicy')),
  },
  {
    exact: true,
    path: '/privacy-policy',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/PrivacyPolicy')),
  },
  {
    exact: true,
    path: '/disclaimer',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Desclaimer')),
  },
  {
    exact: true,
    path: '/profile',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Profile/Profile')),
  },
  {
    exact: true,
    path: '/editprofile',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Profile/Editprofile')),
  },
  {
    exact: true,
    path: '/login',

    component: lazy(() => import('src/views/pages/login/Login')),
  },
  {
    exact: true,
    path: '/create',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Create-NFT/Nftcollection')),
  },
  {
    exact: true,
    path: '/create-single',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/Create-NFT/CreateSingle')),
  },
  // {
  //   exact: true,
  //   path: "/create",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Create-NFT/CreateSingle")),
  // },
  {
    exact: true,
    path: '/nft-details',
    layout: HomeLayout,
    component: lazy(() => import('src/views/pages/NftDetails')),
  },

  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFound')),
  },
  {
    component: () => <Redirect to="/404" />,
  },
]
