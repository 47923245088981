import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Grid,
  Link,
  MenuItem,
  Box,
  Container,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState, useEffect, useContext } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Logo from './../../component/Logo'
import { UserContext } from 'src/context/User'
import { useWeb3React } from '@web3-react/core'
import { Autocomplete } from '@material-ui/lab'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import apiConfig from 'src/asset/apiConfig'
import { ACTIVE_NETWORK, NetworkContextName } from 'src/constants'

const headersData = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Support',
    href: '/',
  },
  // {
  //   label: 'Support',
  //   href: '/suport',
  // },
  {
    label: 'Create',
    href: '/create',
    // href: '/create-collection',
  },
  // {
  //   label: 'Connect',
  //   href: '/Login',
  // },
]

const useStyles = makeStyles((theme) => ({
  header: {
    height: '68px',
    position: 'absolute',
    backgroundColor: 'transparent',
    paddingRight: '0',
    paddingLeft: '0px',
    '@media (max-width: 1280px)': {
      paddingLeft: '0',
      paddingRight: '0px',
      height: '70px',
      paddingTop: '5px',
    },
    '@media (max-width: 900px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
      height: '70px',
      paddingTop: '5px',
    },
  },
  menuButton: {
    size: '16px',
    marginLeft: '16px',
    color: '#000',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    '@media (max-width: 900px)': {
      fontSize: '16px',
      fontWeight: '400',
      fontStyle: 'normal',
      letterSpacing: '-0.6px',
      lineHeight: '1.75',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    '@media (max-width: 900px)': {
      paddingLeft: '75px',
      paddingRight: '20px',
      height: '100%',
    },
  },
  maindrawer: {
    height: '100%',
    background: '#0c0731',
    width: '260px',
  },
  logoDrawer: {
    paddingLeft: '10px',
    width: '60px',
    marginBottom: '30px',
  },
  drawerContainer: {
    padding: '20px 0px ',
    height: '100%',
    background: '#0c0731',
    width: '260px',
  },
  drawericon: {
    color: '#000',
    position: 'absolute',
    top: '3px',
    right: '0px',
    fontSize: '25px',
  },
  logoImg: {
    width: '165px',
    marginTop: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '110px',
    },
  },
  menuMobile: {
    fontSize: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: '-0.6px',
    lineHeight: '1.75',
    color: '#fff',
    borderBottom: '1px solid #3e3e3e',
    padding: '16px',
  },
  paper1: {
    background: 'black',
    color: 'white',
  },
  containerHeight: {
    height: '100%',
  },
  mainHeader: {
    justifyContent: 'space-between',
    padding: '0px',
  },
  search: {
    position: 'relative',
    width: '180px',
    maxWidth: '300px',
    border: '0 ',
    borderRadius: '3px',
    marginRight: '62px',
    boxShadow:
      '0 1px 2px 0 rgba(60, 64, 67, 0.10), 0 2px 6px 2px rgba(60, 64, 67 , 0.05)',
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '@media (max-width: 900px)': {
      width: '145px',
      maxWidth: '300px',
    },
    // marginRight: theme.spacing(2),

    // width: "100%",
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(3),
    //   width: 'auto',
    // },
  },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: 'black',
    width: '100%',
    color: 'rgba(34,196,254,1)',
  },
}))

export default function Header() {
  const {
    header,
    menuMobile,
    menuButton,
    toolbar,
    drawerContainer,
    drawericon,
    logoImg,
    logoDrawer,
    containerHeight,
    search,
    searchIcon,
    mainHeader,
    headerInner,
  } = useStyles()
  const history = useHistory()
  const classes = useStyles()
  const { account, chainId } = useWeb3React()
  const user = useContext(UserContext)
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  })

  const { mobileView, drawerOpen } = state

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }))
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  const [searchInput, setSearchInput] = useState('')
  const [myOptions, setmyOptions] = useState('')

  const onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      if (searchInput !== '') {
        history.push({
          pathname: '/search',
          search: searchInput,
        })
        // alert(searchInput);
      } else {
        setSearchInput('')
        history.push({
          pathname: '/search',
          search: searchInput,
        })
        // alert("empty");
        // window.location.href = "/search";
      }
    }
  }

  // $(document).on("click", "#go", myFunction).on("keypress", this, myFunction);
  const displayDesktop = () => {
    return (
      <Container maxWidth="lg">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            style={{ paddingLeft: '0px' }}
          >
            {/* <div className={search}>
              <div className={searchIcon}>
                <Button
                  onClick={() =>
                    history.push({
                      pathname: "/search",
                      search: searchInput,
                    })
                  }
                >
                  <SearchIcon />
                </Button>
              </div>
              <InputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                value={searchInput}
                // onChange={(e) => setSearchInput(e.target.value)}
                // onClick={() =>
                //   history.push({
                //     pathname: "/search",
                //     search: searchInput,
                //   })
                // }
              />
            </div> */}

            {/* <div className={search}> */}
            {/* <div className={searchIcon}>
                <Button
                  onClick={() =>
                    history.push({
                      pathname: '/search',
                      search: searchInput,
                    })
                  }
                >
                  <SearchIcon />
                </Button>
              </div> */}
            {/* <InputBase
                placeholder="Search…"
                autoFocus={true}
                inputProps={{ 'aria-label': 'search' }}
                value={searchInput}
                onKeyDown={onKeyDown}
                onChange={(e) => setSearchInput(e.target.value)}
              /> */}
            <TextField
              className={classes.margin}
              placeholder="Search by Nft, userName and collection"
              autoFocus={true}
              inputProps={{ 'aria-label': 'search' }}
              value={searchInput}
              onKeyDown={onKeyDown}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() =>
                        history.push({
                          pathname: '/search',
                          search: searchInput,
                        })
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* </div> */}

            {getMenuButtons()}
            <Button
              key="label"
              color="inherit"
              to={'/Login'}
              component={RouterLink}
              className={menuButton}
              style={
                user.isLogin
                  ? {
                      backgroundColor: 'rgba(34,196,254,1)',
                      color: 'white',
                    }
                  : {}
              }
            >
              {account ? 'Profile' : 'Connect'}
            </Button>
          </Grid>
        </Toolbar>
      </Container>
    )
  }

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }))

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />

            {getDrawerChoices()}

            <Link
              component={RouterLink}
              to={'/Login'}
              color="inherit"
              style={{ textDecoration: 'none' }}
              key="label"
            >
              <MenuItem className={menuMobile}>
                {typeof account !== 'undefined' ? 'Profile' : 'Connect'}
              </MenuItem>
            </Link>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <div>
          <div className={search}>
            {/* <Button onClick={searchHandler}> */}
            <div className={searchIcon}>
              <Button
                onClick={() =>
                  history.push({
                    pathname: '/search',
                    search: searchInput,
                  })
                }
              >
                <SearchIcon />
              </Button>
            </div>
            {/* </Button> */}
            <InputBase
              placeholder="Search by Nft, userName and collection"
              inputProps={{ 'aria-label': 'search' }}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onClick={() =>
                history.push({
                  pathname: '/search',
                  search: searchInput,
                })
              }
            />
          </div>
          <IconButton
            className={drawericon}
            {...{
              edge: 'start',
              color: 'inherit',
              'aria-label': 'menu',
              'aria-haspopup': 'true',
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon width="60px" height="60px" />
          </IconButton>
        </div>
      </Toolbar>
    )
  }

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: 'inherit',
            style: { textDecoration: 'none' },
            key: label,
          }}
        >
          <MenuItem className={menuMobile}>{label}</MenuItem>
        </Link>
      )
    })
  }

  const femmecubatorLogo = (
    <Box>
      <Link href="/">
        <Logo className={logoImg} />
      </Link>
    </Box>
  )

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: 'inherit',
            to: href,
            component: RouterLink,
            className: menuButton,
          }}
        >
          {label}
        </Button>
      )
    })
  }

  return (
    <AppBar
      position="relative"
      className={
        history.location.pathname !== '/' ? { header } : { headerInner }
      }
      elevation={0}
      style={
        history.location.pathname !== '/'
          ? { backgroundColor: '#fff', position: 'relative !important' }
          : { backgroundColor: 'transparent', position: 'absolute' }
      }
    >
      <Container
        maxWidth={history.location.pathname !== '/' ? 'lg' : 'fixed'}
        className={containerHeight}
      >
        {mobileView ? displayMobile() : displayDesktop()}
      </Container>
    </AppBar>
  )
}
