import React, { createContext, useEffect, useState } from 'react'
import { injected } from 'src/connectors'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import apiConfig from 'src/asset/apiConfig'
import { ACTIVE_NETWORK } from 'src/constants'

export const UserContext = createContext()

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem('userAddress', userAddress)
  } else {
    sessionStorage.removeItem('userAddress')
  }
}

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem('userToken', token)
  } else {
    sessionStorage.removeItem('userToken')
  }
}

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate } = useWeb3React()

  const [isLogin, setIsLogin] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState()
  const [cookiesPolicy, setCookiesPolicy] = useState()
  const [terms, setTerms] = useState()
  const [disclaimer, setDisclaimer] = useState()
  const [legalNotice, setlegalNotice] = useState()
  let data = {
    updateUser: (account) => {
      setSession(account)
    },
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          activate(injected)
        }
      })
    },
    isLogin,
    privacyPolicy,
    cookiesPolicy,
    terms,
    disclaimer,
    legalNotice,
  }

  useEffect(() => {
    if (account) {
      connectWalletHandler(account)
    }
  }, [account])

  const getStaticData = async () => {
    try {
      const res = await axios.get(apiConfig.staticList)
      if (res.data.response_code === 200) {
        let tempPrivacyPolicy = res.data.result.filter(
          (data) => data.type === 'PrivacyPolicy',
        )

        let tempCookiesPolicy = res.data.result.filter(
          (data) => data.type === 'CookiesPolicy',
        )
        let tempTC = res.data.result.filter((data) => data.type === 'T&C')

        let tempDisclaimer = res.data.result.filter(
          (data) => data.type === 'Disclaimer',
        )
        let tempLegalNotice = res.data.result.filter(
          (data) => data.type === 'LegalNotice',
        )
        console.log('tempDisclaimer', tempDisclaimer[0].description)
        setPrivacyPolicy(tempPrivacyPolicy[0].description)
        setCookiesPolicy(tempCookiesPolicy[0].description)
        setTerms(tempTC[0].description)
        setDisclaimer(tempDisclaimer[0].description)
        setlegalNotice(tempLegalNotice[0].description)
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  useEffect(() => {
    getStaticData()
  }, [])

  console.log('account', account)

  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(apiConfig.connectWallet, {
        walletAddress,
      })
      if (res.data.response_code === 200) {
        setTokenSession(res.data.result.token)
        setIsLogin(true)
      } else {
        setIsLogin(false)
        deactivate()
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  }
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler()
        }
      }
    }
  }, [chainId, account])
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + ACTIVE_NETWORK.toString(16) }],
      })
    } catch (error) {
      console.log('ERROR', error)

      // toast.warn(error.message)

      // if (error.code === 4902) {
      //   addNetworkHandler();
      // }
    }
  }
  useEffect(() => {
    const userAddress = window.sessionStorage.getItem('userAddress')
    if (userAddress) {
      data.connectWallet()
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    data.updateUser(account)
  }, [account]) //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  )
}
